import React , {useEffect , useState } from 'react'
import '../../assets/css/style.css';
import useAuth from '../../Auth/useAuth';
import { useLocation , useHistory , NavLink  } from 'react-router-dom';
const Navbar = () => {
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();

    const [iconClass, setIconClass] = useState('ri-menu-fill');
    const [navbarMobile, setNavbarMobile] = useState(false);

    const toggleNavbar = () => {
      setNavbarMobile(!navbarMobile);
      setIconClass(navbarMobile ? 'ri-menu-fill' : 'ri-close-line');
    };
  


    useEffect(() => {
        const scrollto = (el) => {
          let header = document.getElementById('header');
          let offset = header.offsetHeight;
    
          let elementPos = document.querySelector(el).offsetTop;
          window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
          });
        }
    
        const selectHeader = document.getElementById('header');
    
        if (selectHeader) {
          const headerScrolled = () => {
            if (window.scrollY > 100) {
              selectHeader.classList.add('header-scrolled');
            } else {
              selectHeader.classList.remove('header-scrolled');
            }
          };
    
          window.addEventListener('load', headerScrolled);
          window.addEventListener('scroll', headerScrolled);
    
          return () => {
            window.removeEventListener('load', headerScrolled);
            window.removeEventListener('scroll', headerScrolled);
          };
        }
      }, []);

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }


    const Navegar = (pad, index) =>{

        let navbar = select('#navbar')

        if (navbar.classList.contains('navbar-mobile')) {

            toggleNavbar();

        }

        let navbarlinks = select('#navbar .scrollto', true)

        // Quita la clase "active" de todos los elementos
        navbarlinks.forEach(link => {
            link.classList.remove('active');
        });

        // Aquí decides cuál enlace debe tener la clase "active"
        const indiceDelEnlaceActivo = index; // Por ejemplo, si deseas que el tercer enlace tenga la clase "active"

        // Agrega la clase "active" solo al enlace deseado
        if (navbarlinks[indiceDelEnlaceActivo]) {
        navbarlinks[indiceDelEnlaceActivo].classList.add('active');
        }
  
       
        if(location.pathname!=='/'){
            history.push("/"+pad);
        }
    }

    const Click = () =>{
        let navbar = select('#navbar')

        if (navbar.classList.contains('navbar-mobile')) {
            toggleNavbar();
        }
        window.scrollTo(0, 0)
    }

    const Cerrar = () =>{
        Click();
        auth.logout()
    }

    

    const Router = (pad ,e) =>{

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            toggleNavbar();
        }

        let navbarlinks = select('#navbar .scrollto', true)

        if(pad === "blogs"){
            navbarlinks[3].classList.add('active')
            navbarlinks[0].classList.remove('active')
            navbarlinks[1].classList.remove('active')
            navbarlinks[2].classList.remove('active')
            navbarlinks[4].classList.remove('active')
        }
        if(pad === "equipo"){
            navbarlinks[4].classList.add('active')
            navbarlinks[0].classList.remove('active')
            navbarlinks[1].classList.remove('active')
            navbarlinks[2].classList.remove('active')
            navbarlinks[3].classList.remove('active')
        }

        history.push("/"+pad);
    }

    return (
        <>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center justify-content-lg-between">
                    <div onClick={()=>Navegar('#hero',0)} style={{display:'flex', alignItems: 'center'}} className="designLogo logo me-auto me-lg-0" id="Logo">
                        <img src="../../assets/img/logo.png" width="50px" alt="" ></img>
                        <p id="Texto-logo"   style={{margin:'0',fontSize:'20px'}}>ompañías Ec</p>
                    </div>
                   
                   
                    <nav id="navbar" className={`navbar order-last order-lg-0 ${navbarMobile ? 'navbar-mobile' : ''}`}>
                        <ul>
                            {
                                 auth.isLogged() && location.pathname === '/administracion' || location.pathname === '/mensajes' || location.pathname === '/alertas' ?
                                 <>
                                    <li onClick={()=>Click()}><NavLink className="nav-link scrollto" to="/administracion">Blogs</NavLink></li>
                                    <li onClick={()=>Click()}><NavLink className="nav-link scrollto" to="/mensajes">Mensajes</NavLink></li>
                                    <li onClick={()=>Click()}><NavLink className="nav-link scrollto" to="/alertas">Alertas</NavLink></li>
                                    <li onClick={()=>Cerrar()}><a className="nav-link scrollto" >Cerrar sesión</a></li>
                                 </>
                                 :
                                 <>
                                    <li onClick={()=>Navegar('#hero',0)}><a className="nav-link scrollto active" href="#hero">Inicio</a></li>
                                    <li onClick={()=>Navegar('#about',1)}><a className="nav-link scrollto" href="#about">Información</a></li>
                                    <li onClick={()=>Navegar('#services',2)}><a className="nav-link scrollto" href="#services">Servicios</a></li>
                                    <li onClick={(e)=>Router('blogs',e)}><a className="nav-link scrollto">Blog</a></li>
                                    <li onClick={(e)=>Router('equipo',e)}><a className="nav-link scrollto">Equipo</a></li>
                                 </>
                            }
                            
                        </ul>
                        <i onClick={toggleNavbar} className={`ri mobile-nav-toggle ${iconClass}`} ></i>
                    </nav>
                        {
                            location.pathname !== '/administracion' && location.pathname !== '/mensajes' && location.pathname !== '/alertas'  && (
                                <li style={{listStyle:'none'}}  onClick={()=>Navegar('#cta')}><a className="get-started-btn scrollto" href="#cta">Agenda tu cita</a></li>
                            )
                        }
                                
                </div>
            </header>
        </>
    )
}

export default Navbar

