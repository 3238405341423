import React , {useEffect , useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { CaretLeftOutlined , CaretRightOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
const DataPaginada = (props) => {
    const history = useHistory();
    const { data , Offset } = props;

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const [OFFSET, setOFFSET] = useState(Offset);

    const itemsPerPage = 2;

    useEffect(() => {
        if(Offset=="SI"){
            setOFFSET("SI");
        }

        const endOffset = itemOffset + itemsPerPage;
        if(OFFSET=="SI"){
            handlePageClickAuto(0);
        }
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage , data ]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        setOFFSET("NO");
    };

    const handlePageClickAuto = (event) => {
        const newOffset = (event * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };

    const VerBlog = (blog,title) =>{
        let Id = blog
        history.push({
            pathname: '/blog/Ver/'+Id,
            state: { title: title }
        })
    }

    const RetornarFecha = (fecha) => {

        const createReview = new Date(fecha);
        let Fecha =
          (createReview.getDate() < 10 ? "0" : "") +
          createReview.getDate() +
          "-" +
          (createReview.getMonth() + 1 < 10 ? "0" : "") +
          (createReview.getMonth() + 1) +
          "-" +
          createReview.getFullYear();
        return Fecha;

    };

    const BLOGS_ALL = currentItems.map((Estados,index) =>{


        let Imagen = Estados.Host + Estados.Portada;

        let FECHA = RetornarFecha(Estados.FechaRestada);
        let DESCRIPCION = Estados.Descripcion;
        let CREADOR = Estados.NombreAutor;

        return (
            <article key={index} className="entry">

                <div className="entry-img">
                    <img src={Imagen} alt="" className="img-fluid"></img>
                </div>

                <h2 className="entry-title">
                    <a onClick={()=>VerBlog(Estados.Id,Estados.Titulo)}>{Estados.Titulo}</a>
                </h2>

                <div className="entry-meta">
                    <ul>
                        <li style={{alignItems:'center'}} className="d-flex align-data-center"><img src={Estados.FotoAutor} style={{marginRight: '10px', width:'25px'}} className="rounded-circle float-left" width="30px" alt=""></img> <a href="blog-single.html">{CREADOR}</a></li>
                        <li className="d-flex align-data-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time dateTime={FECHA}>{FECHA}</time></a></li>
                    </ul>
                </div>

                <div className="entry-content">
                    <p style={{textAlign:'justify'}}>{DESCRIPCION} ...</p>
                    <div className="read-more">
                    <a onClick={()=>VerBlog(Estados.Id,Estados.Titulo)} >Leer mas</a>
                    </div>
                </div>

            </article>
        )
    });


    return (
        <>
        {BLOGS_ALL}
        <ReactPaginate
            breakLabel="..."
            nextLabel={<CaretRightOutlined />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            initialPage={Offset}
            pageCount={pageCount}
            previousLabel={<CaretLeftOutlined />}
            
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            activeLinkClassName = {"paginationActiveLink"}
            pageClassName = {"pageClassName"}
        />
        </>
    );
}

export default DataPaginada