import React  from 'react'
import { BrowserRouter as Router , Route , Switch } from 'react-router-dom'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import DetalleBlog from '../Pages/BlogPage/DetalleBlog'

import Blogs from '../Pages/BlogPage/'

import Error404 from '../Pages/Error404'
import HomePage from '../Pages/HomePage'
import LoginPage from '../Pages/LoginPage'

import PublicRoute from './PublicRoute'
import EquipoPage from '../Pages/EquipoPage'

import AdminBlogs from '../Pages/DashboardPage/AdminBlogs'
import AdminMensajes from '../Pages/DashboardPage/AdminMensajes'
import AdminAlertas from '../Pages/DashboardPage/AdminAlertas'

import PivateRoute from './PivateRoute'
import ServicePage from '../Pages/ServicePages'

const AppRouter = () => {


    return (
        <Router
        >
            <Navbar/>
            <Switch>

                {/* RUTAS COMUNES */}

                {/* <Route exact path="/blog" component={BlogPage}/> */}
                {/* <Route exact  path="/contact" component={ContactPage}/> */}

                {/* RUTAS PUBLICAS */}

                <PublicRoute exact  path="/login" component={LoginPage}/>
                <Route exact  path="/blogs" component={Blogs}/>
                <Route exact  path="/equipo" component={EquipoPage}/>
                <Route exact  path="/servicio/:id" component={ServicePage}/>

                {/* <PublicRoute exact  path="/register" component={RegisterPage}/> */}

                {/* RUTAS PRIVADAS */}

                {/* <PivateRoute exact  path="/dashboard" component={ListaSolicitudes}/> */}
                <Route exact path="/blog/:accion/:id" component={DetalleBlog}/>
                {/* <PivateRoute exact  path="/pyments" component={PymentsPage}/> */}

                <PivateRoute exact  path="/administracion" component={AdminBlogs}/>
                <PivateRoute exact  path="/mensajes" component={AdminMensajes}/>
                <PivateRoute exact  path="/alertas" component={AdminAlertas}/>

                {/* RUTAS POR DEFECTO */}

                <Route exact path="/" component={HomePage}/>
                <Route exact path="*" component={Error404}/>

            </Switch>
            <Footer/>
        </Router>
    )
}

export default AppRouter
