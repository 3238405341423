// eslint-disable-next-line no-unused-vars

import './App.css';
import AuthProvider from './Auth/AuthProvider';
import AppRouter from './Routers/AppRouter';
// import React , {useEffect , useState } from 'react'

function App() {


  // Estado para controlar si la página ha cargado completamente
  // const [pageLoaded, setPageLoaded] = useState(false);
  
  // Efecto para suscribirse al evento onload cuando el componente se monta
  // useEffect(() => {
  //   const handleLoad = () => {
  //     // Tu lógica aquí
  //     setPageLoaded(true);
  //   };

  //   // Suscribirse al evento onload
  //   window.addEventListener('load', handleLoad);

  //   // Limpiar el evento onload cuando el componente se desmonte
  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //   };
  // }, []);


  return (
    <div>
      <AuthProvider>
        <AppRouter/>
      </AuthProvider>
      {/* {!pageLoaded && <div id="preloader"></div>} */}
    </div>
  );
}

export default App;

