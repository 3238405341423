import React , {useState} from 'react'
import { useHistory ,useLocation} from 'react-router-dom';
import host from '../../Util/host';
import {
    message
} from "antd";
import '../../assets/css/style.css';
const Footer = () => {
    const location = useLocation();
    const history = useHistory();

    const [Data, setData] = useState({
        email:""
    });

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
    }
    const Navegar = (pad) =>{
       
        if(location.pathname!=='/'){
            history.push("/"+pad);
            window.location.reload(true);
        }
    }

    const Router = (pad ,e) =>{

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            navbar.classList.remove('navbar-mobile')
            let navbarToggle = select('.mobile-nav-toggle')
            navbarToggle.classList.toggle('bi-list')
            navbarToggle.classList.toggle('bi-x')
        }

        let navbarlinks = select('#navbar .scrollto', true)

        if(pad === "blogs"){
            navbarlinks[3].classList.add('active')
            navbarlinks[0].classList.remove('active')
            navbarlinks[1].classList.remove('active')
            navbarlinks[2].classList.remove('active')
            navbarlinks[4].classList.remove('active')
        }
        if(pad === "equipo"){
            navbarlinks[4].classList.add('active')
            navbarlinks[0].classList.remove('active')
            navbarlinks[1].classList.remove('active')
            navbarlinks[2].classList.remove('active')
            navbarlinks[3].classList.remove('active')
        }

        

        history.push("/"+pad);
        window.scrollTo(0,0)
    }

    const VALIDAR_FORMULARIO = () =>{

        if(
            VALIDAR_CORREO()
        ){
            return true;
        }
        
    }

    const VALIDAR_CORREO = () =>{
        const  rex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(rex.test(Data.email)){
            return true;
        }
        message.error("Ingrese un correo válido");
        return false;
    }

    const ENVIAR_ALERTA = async () => {

  
        if (VALIDAR_FORMULARIO()) {
            let JsonGuardar = {
                token:"015ec0d618fe3fa9c7d0a1abda2b159c",
                Correo:Data.email
            }
    
            fetch(host+'/API_COMPANIAS/procedures/alertas/create.php',{
                method:'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },    
                body: JSON.stringify(JsonGuardar)                      
            }).then(response => response.json())
            .then((responseJson)=>{
    
                if(responseJson.status==="OK"){
                    setData({
                        ...Data,
                        email: ""
                    })
                    message.success(responseJson.message);
                }else{
                    message.error(responseJson.message);
                }
                
            })
            .catch(function(error) {
                console.error(error);             
            });
          
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((Data) => ({
          ...Data,
          [name]: value,
        }));
    };

    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-info">
                                    <h3 style={{color:'white'}}>Compañías_ec<span>.</span></h3>
                                    <p>
                                        Guayaquil<br></br>
                                        Guayas, Ecuador<br></br><br></br>
                                        <strong>Telefono:</strong> +593 999 459 124<br></br>
                                        <strong>Correo:</strong> info@companiasec.com<br></br>
                                    </p>
                                    <div className="social-links mt-3">
                                        <a className="twitter"><i className="bx bxl-twitter"></i></a>
                                        <a className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="https://www.instagram.com/p/Ca2AHdVrwEl/?igshid=MDJmNzVkMjY%3D" target="_blank" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        <a className="google-plus"><i className="bx bxl-skype"></i></a>
                                        <a className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Enlaces Útiles</h4>
                                <ul>
                                <li onClick={()=>Navegar('#features')}><i className="bx bx-chevron-right"></i> <a href="#features">Misión</a></li>
                                <li onClick={()=>Navegar('#features')}><i className="bx bx-chevron-right"></i> <a href="#features">Visión</a></li>
                                <li onClick={(e)=>Router('blogs',e)}><i className="bx bx-chevron-right"></i><a>Blog</a></li>
                                <li onClick={(e)=>Router('equipo',e)}><i className="bx bx-chevron-right"></i><a>Equipo</a></li>
                                <li onClick={(e)=>Navegar('#contact')}><i className="bx bx-chevron-right"></i><a href="#contact">Contáctanos</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Otros Servicios</h4>
                                <ul>
                                <li><i className="bx bx-chevron-right"></i> <a target='_blank' href='https://www.instagram.com/companias_ec/'>Instagram</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Recibe ofertas</h4>
                                <p>Te mantendremos al día de eventos gratuitos , dejanos tu correo para que no te los pierdas.</p>
                                <form>
                                    <input type="email" onChange={handleChange} value={Data.email} name="email"></input>
                                    <input type="button" onClick={()=>ENVIAR_ALERTA()} value="Enviar"></input>
                                </form>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Compañías_ec</span></strong>. Todos los derechos reservados
                    </div>
                    <div className="credits">
                        Desarrollado por <a target='_blank' href='https://www.linkedin.com/in/sebastian-alvarado-a01a4a195' style={{color:'#f50057'}}>Sebastian Alvarado</a>
                    </div>
                </div>
            </footer>
            
            <a onClick={()=>window.scrollTo(0,0)} className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" style={{color:'white'}}></i></a>
        </>
    )
}

export default Footer

