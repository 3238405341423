import host from '../Util/host';
async function ObtenerBlogsId(Id,Web){
    const UrlApi = host+'/API_COMPANIAS/procedures/blog/read_by_id.php?token=015ec0d618fe3fa9c7d0a1abda2b159c&id='+Id+'&Web='+Web;
        try{
            let response= await fetch(UrlApi);
            let responseJson= await response.json();
            let res= await responseJson.body[0];
            return res;
        }catch(error){
            console.error(`ERROR BLOGS ID ES :  ${error}`)
        }
}
export  {ObtenerBlogsId};