import React, { useState,useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import { ObtenerCategoriasAll } from '../../../Api/ObtenerCategoriasAll';
import { ObtenerWebs } from '../../../Api/ObtenerWebs';
import { LoadingOutlined, PlusOutlined , CopyOutlined } from '@ant-design/icons';
import host from '../../../Util/host';
import { makeStyles } from "@material-ui/core/styles";
import { ObtenerBlogsAll } from '../../../Api/ObtenerBlogsAll'
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Popconfirm,
  message,
  Upload,
  Select ,
  Tag 
} from "antd";

const { Option } = Select;

const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 0,
  },
};
const pagination = {
  pageSize: 5,
};

const { TextArea } = Input;

const AdminBlogs = () => {

  const editor = useRef(null)
	const [content, setContent] = useState('')

  

  const [Usuario, setUsuario] = React.useState(JSON.parse(localStorage.getItem('User')));

  const [categorias ,setCategorias] = useState([]);

  const [webs ,setWebs] = useState([]);
  

	const config = {
		readonly: false,
    height:520
	}

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

const ObtenerImagen=(e)=>{

    getBase64(e.file.originFileObj, FotoPortada =>
        setBlogs({
            ...blog,
            FotoPortada: FotoPortada,
            loading: false,
        }),
    );
}


  const styles = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const AbrirModal = () => {
    setModal(!modal);
  };
  const [LabelModal, setLabelModal] = useState("");

  const Blog = {
    Id: "",
    Titulo: "",
    FotoPortada: "",
    loading: false,
    FechaPublicacion: "",
    Descripcion:"",
    Categoria:"",
    Web:"",
    Tags:"",
    Contenido:"",
    NombreAutor:"",
    Estado:""
  };

  
  const [ImgConverter, setImgConverter] = useState({
    Original:"",
    Link:""
  });

  const [blog, setBlogs] = useState(Blog);

  useEffect(() => {
      GetWebs();
  }, []);
  
  useEffect(() => {

    GetBlogs();
    setLoading(true);
     
  }, []);

  const GetBlogs = async () => {

    const Datos = await ObtenerBlogsAll();
    setData(Datos);

    const Categorias = await ObtenerCategoriasAll("COMPANIAS_EC");
    setCategorias(Categorias);

    setLoading(false);
  };

  const GetWebs = async () => {
      const Webs = await ObtenerWebs();
      setWebs(Webs);
  };

  const ControlModales = (data, name) => {

    const storedUser = JSON.parse(localStorage.getItem('User'));
    setUsuario(storedUser);

    setBlogs(data);
    setContent(data.Contenido);
    setLabelModal(name);
    AbrirModal();
  };

  const ControlOperaciones = (name) => {
    const OPCION_OPERACIONES = {
      Ingresar: () => {
        CrearUsuarios();
      },
      Editar: () => {
        EditarUsuarios();
      },
    };
    OPCION_OPERACIONES[name]();
  };

  const uploadButton = (
    <div>
        {blog.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Foto</div>
    </div>
  );


  const columns = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      //fixed: "left",
      // ...getColumnSearchProps('username','Nombre'),
      width: 50,
    },
    {
        title: "Título",
        dataIndex: "Titulo",
        key: "Titulo",
        //fixed: "left",
        // ...getColumnSearchProps('username','Nombre'),
        width: 200,
      },
    {
      title: "Foto Portada",
      dataIndex: "FotoPortada",
      key: "FotoPortada",
      width: 150,
      render: (e) => (
        <img src={e} alt="avatar" style={{ width: '80%' }} />
      ),
    },
    {
      title: "Sitio Web",
      dataIndex: "Web",
      key: "Web",
      width: 200,
      render: (e) => (
        <Tag color="green">{e}</Tag>
      )
    },
    {
      title: "Fecha Publicación",
      dataIndex: "FechaRestada",
      key: "FechaRestada",
      width: 200,
    },
    {
      title: "Descripción",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: 400,
    },
    {
      title: "Categoría",
      dataIndex: "Categoria",
      key: "Categoria",
      width: 100,
    },
    {
      title: "Tags",
      dataIndex: "Tags",
      key: "Tags",
      width: 100,
    },
    {
      title: "Nombre Autor",
      dataIndex: "NombreAutor",
      key: "NombreAutor",
      width: 100,
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "Estado",
      width: 100,
    },
    {
      title: "Acciones",
      key: "acciones",
      fixed: "right",
      render: (fila) => (
        <>
          <Button type="primary" onClick={() => ControlModales(fila, "Editar")}>
            Editar
          </Button>{" "}
          <Popconfirm
            placement="topRight"
            title={"¿Desea eliminar este blog?"}
            onConfirm={() => confirm(fila)}
            okText="Si"
            cancelText="No"
          >
            <Button type="primary" danger>
              Eliminar
            </Button>
          </Popconfirm>
        </>
      ),
      width: 200,
    },
  ];

  const VALIDAR_FORMULARIO = () => {
    if(
        VALIDAR_TITULO() &&
        VALIDAR_PORTADA() &&
        VALIDAR_CATEGORIA() &&
        VALIDAR_TAGS() &&
        VALIDAR_DESCRIPCION() &&
        VALIDAR_CONTENIDO() 
    ){
      return true;
    }
    return false;
  }

  const VALIDAR_TITULO = () => {
    if(blog.Titulo=="" || blog.Titulo==null){
      message.error("Ingrese el titulo");
      return false;
    }
    return true;
  }
  const VALIDAR_PORTADA = () => {

    if(LabelModal==="Ingresar"){
      if(blog.FotoPortada=="" || blog.FotoPortada==null){
        message.error("Ingrese la foto de portada");
        return false;
      }
    }
      
    return true;
  }

  const VALIDAR_CATEGORIA = () => {
    if(blog.Categoria=="" || blog.Categoria==null){
      message.error("Seleccione la categoría");
      return false;
    }
    return true;
  }

  const VALIDAR_TAGS = () => {
    if(blog.Tags=="" || blog.Tags==null){
      message.error("Ingrese los Tags");
      return false;
    }
    return true;
  }

  const VALIDAR_DESCRIPCION = () => {
    if(blog.Descripcion=="" || blog.Descripcion==null){
      message.error("Ingrese la Descripcion");
      return false;
    }
    return true;
  }

  const VALIDAR_CONTENIDO = () => {
    if(content=="" || content==null){
      message.error("Escriba algo en su blog, no puede estar vacio");
      return false;
    }
    return true;
  }

  

  const CrearUsuarios = async () => {

  
    if (VALIDAR_FORMULARIO()) {
        let JsonGuardar = {
            token:"015ec0d618fe3fa9c7d0a1abda2b159c",
            Titulo:blog.Titulo,
            Portada:blog.FotoPortada,
            Descripcion:blog.Descripcion,
            Categoria:blog.Categoria,
            Web:blog.Web,
            Tags:blog.Tags,
            Contenido:content,
            Autor:Usuario.Id,
        }

        fetch(host+'/API_COMPANIAS/procedures/blog/create.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetBlogs();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
      
    }
  };

  const confirm = (dato) => {
    EliminarPost(dato.Id);
  };

  const EliminarPost = (Id) =>{
    let JsonGuardar = {
        token:"015ec0d618fe3fa9c7d0a1abda2b159c",
        Id:Id
    }
    fetch(host+'/API_COMPANIAS/procedures/blog/delete.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetBlogs();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
  }

  const EditarUsuarios = async () => {
    if (VALIDAR_FORMULARIO()) {

        let FlagFoto = false;

        if(blog.FotoPortada.substr(0,1) == "d"){
          FlagFoto = true;
        }

        let JsonGuardar = {
          token:"015ec0d618fe3fa9c7d0a1abda2b159c",
          Id:blog.Id,
          Titulo:blog.Titulo,
          Portada:blog.FotoPortada,
          Descripcion:blog.Descripcion,
          Categoria:blog.Categoria,
          Web:blog.Web,
          Tags:blog.Tags,
          Contenido:content,
          Autor:Usuario.Id,
          FlagFoto:FlagFoto
        }

        fetch(host+'/API_COMPANIAS/procedures/blog/update.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetBlogs();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
      
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBlogs((blog) => ({
      ...blog,
      [name]: value,
    }));
  };

  

  const handleChangeConvertirLink = (e) => {
    const { name, value } = e.target;
    setImgConverter((img) => ({
      ...img,
      [name]: value,
    }));

    if(ImgConverter.Original === ""){
      setImgConverter((img) => ({
        ...img,
        Link: "",
      }));
    }
  };

  const GENERAR_LINK = (e) => {
    if(ImgConverter.Original !==""){

      let LINK_API = "https://drive.google.com/uc?id=";
      // https://drive.google.com/file/d/1PsKtJFjG5Aa20DRnV2YIELRboh7cgNtt/view?usp=sharing
      let ID =  ImgConverter.Original.split("/d/")[1]?.split("/")[0];

      if(ID!==undefined){
        let LINK = LINK_API + ID ;

        setImgConverter((img) => ({
          ...img,
          Link: LINK,
        }));
  
      }else{
        message.error("Formato de imagen incorrecto");
      }

      
    }else{
      message.error("Ingrese el enlace de la foto");
    }
  };

  const handleChangeCategoria = (value) => {
    setBlogs((blog) => ({
      ...blog,
      Categoria: value,
    }));
  };

  const handleChangeWeb = (value) => {
    setBlogs((blog) => ({
      ...blog,
      Web: value,
    }));
  };

  const handleChangeContenido = (contenido) =>{
    setContent(contenido)
  }

  const CATEGORIAS = categorias.map((Estados) =>{
      return (
        <Option key={Estados.key} value={Estados.Descripcion}>{Estados.Descripcion}</Option>
      )
  });

  const WEBS = webs.map((Estados) =>{
    return (
      <Option key={Estados.key} value={Estados.Descripcion}>{Estados.Descripcion}</Option>
    )
  });

  const handleCopy = () => {
    if(ImgConverter.Link!==""){
      navigator.clipboard.writeText(ImgConverter.Link);
      message.success('Link copiado');
    }
  } 


  return (
    <>
    <section id="hero-admin" className="d-flex align-items-center justify-content-center breadcrumbs">
      <div className="container">
          <ol>
              <li><a href="">Inicio</a></li>
              <li>Blogs</li>
          </ol>
          <h2 style={{color:'white'}}>{"Administrar Blogs"}</h2>
      </div>
    </section>
    <div className={styles.Contenedor}>
      <Button
        className={styles.BtnIngresar}
        type="primary"
        onClick={() => ControlModales(Blog, "Ingresar")}
      >
        Nuevo
      </Button>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        key={data.key}
        dataSource={data}
        pagination={pagination}
        scroll={{ y: 350, x: 900 }}
      />

      <Modal
        open={modal}
        title={LabelModal + " Blog"}
        destroyOnClose={true}
        onCancel={AbrirModal}
        centered
        style={{width:'90%'}}
        width={2000}
        footer={[
          <Button onClick={AbrirModal}>Cancelar</Button>,
          <Button type="primary" onClick={() => ControlOperaciones(LabelModal)}>
            {LabelModal}
          </Button>,
        ]}
      >
        <div className="row">
          <div className="col-lg-4">
            <Form {...layout}>
                <Item label="Id" hidden>
                  <Input
                    name="Id"
                    onChange={handleChange}
                    value={blog && blog.Id}
                  />
                </Item>

                <Item label="Título" >
                  <Input
                    name="Titulo"
                    onChange={handleChange}
                    value={blog && blog.Titulo}
                  />
                </Item>

                <Item label="Portada" >

                  <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      onChange={(e)=>ObtenerImagen(e)}
                  >

                    {blog.FotoPortada ? <img src={blog.FotoPortada} alt="avatar" style={{ width: '80%' }} /> : uploadButton}
                     
                  </Upload>
                </Item>

                <Item label="Categoria" >
                  <Select defaultValue="lucy" style={{ width: '100%' }} onChange={handleChangeCategoria} value={blog && blog.Categoria}>
                    {CATEGORIAS}
                  </Select>
                </Item>

                <Item label="Web" >
                  <Select defaultValue="lucy" style={{ width: '100%' }} onChange={handleChangeWeb} value={blog && blog.Web}>
                    {WEBS}
                  </Select>
                </Item>

                <Item label="Tags" >
                  <Input
                    name="Tags"
                    onChange={handleChange}
                    value={blog && blog.Tags}
                  />
                </Item>

                <Item label="Descripcion" >
                  <TextArea
                    style={{height:'100px !important'}}
                    name="Descripcion"
                    onChange={handleChange}
                    value={blog && blog.Descripcion}
                  />
                </Item>


                  <Item label="NombreAutor" >
                    <Input
                      name="NombreAutor"
                      // onChange={handleChange}
                      value={Usuario && Usuario.Nombre ? Usuario.Nombre : ''}
                    />
                  </Item>

                
              </Form>
          </div>
          <div className="col-lg-8 entries">
            
              

              <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={handleChangeContenido} // preferred to use only this option to update the content for performance reasons
                  
                  onChange={newContent => {}}
              />
              <div style={{marginTop:'20px'}} className="row mb-5">

                <div className="col-12 col-md-6 col-lg-6 mb-2">
                  <Input.Group compact>
                    <Input style={{ width: 'calc(100% - 100px)' }}
                          name="Original"
                          placeholder="INGRESE EL LINK DE GOOGLE DRIVE"
                          onBlur={handleChangeConvertirLink}
                      />
                      <Button
                          style={{width:'100px'}}
                          type="primary"
                          onClick={()=>GENERAR_LINK()}
                      >Generar</Button>
                  </Input.Group>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <Input
                      addonAfter={<CopyOutlined onClick={handleCopy} />}
                      name="Link"
                      value={ImgConverter.Link}
                  />
                </div>

              </div>
              
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};

export default AdminBlogs;

const useStyles = makeStyles((theme) => ({
  Contenedor:{
    height:'100vh' , 
    paddingLeft:'110px' , 
    paddingRight:'110px'
  },
  table: {
    width: '100%',
    margin: '0 auto'
  },
  BtnIngresar: {
    margin: "30px 0px 20px 0px",
    whiteSpace: "nowrap"
  },
  "@media (max-width: 750px)": {
    Contenedor:{
      height:'100vh' , 
      paddingLeft:'10px' , 
      paddingRight:'10px'
    }
  }
}));
