import React from 'react'

const Error404 = () => {
    return (
        <div>
            <section id="hero-admin" className="d-flex align-items-center justify-content-center breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="">Inicio</a></li>
                        <li>Error 404</li>
                    </ol>
                    <h2 style={{color:'white'}}>{"Error 404"}</h2>
                </div>
            </section>
            {/* <h1>Error 404</h1> */}
        </div>
    )
}

export default Error404
