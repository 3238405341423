import React , {useEffect , useState } from 'react'
import Swiper from 'swiper';
import { InlineWidget } from "react-calendly";
import { ObtenerBlogs } from '../../Api/ObtenerBlogs';
import { ObtenerServicios } from '../../Api/ObtenerServicios';
import { ObtenerCategorias } from '../../Api/ObtenerCategorias';
import { useHistory } from 'react-router-dom';
import host from '../../Util/host';
import { Steps , Modal , Button , message , Carousel } from 'antd';
import {Link as LinkS} from 'react-scroll'

const HomePage = () => {


    const steps = [
        {
          title: 'Reserva tu cita',
          description: 'Selecciona la fecha y la hora',
        },
        {
          title: 'Realiza el pago',
          subTitle: 'antes de las 24 horas',
          description: 'La cita se cancelará si no se ha efectuado el pago',
        },
        {
          title: 'Esté atento a su cita',
          description: 'Le informaremos vía correo electrónico',
        },
      ];
      

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const [modal, setModal] = useState(false);
    const AbrirModal = () => {
        setModal(!modal);
    };

    const [proyectCount, setProyectCount] = useState(0);
    const [experienceCount, setExperienceCount] = useState(0);
    const [happyClient, setHappyClient] = useState(0);
    const [awardsCount, setAwardsCount] = useState(0);

    const history = useHistory();

    const [data ,setData] = useState([]);

    const [dataFiltrada ,setDataFiltrada] = useState([]);

    const [categorias ,setCategorias] = useState([]);

    const [servicios ,setServicios] = useState([]);

    const [infoServicios , setInfoServicios] = useState({
        Titulo : "",
        Contenido : ""
    });

    const [mensaje , setMensaje] = useState({
        Nombre : "",
        Correo : "",
        Tema : "",
        Mensaje: ""
    });


    useEffect(()=>{

        setProyectCount(0)
        setExperienceCount(0)
        setHappyClient(0)
        setAwardsCount(0)

        const doctorsInterval = setInterval(() => {
            setProyectCount(prevCount => {
              const newCount = prevCount + 1;
              if (newCount === 32) clearInterval(doctorsInterval);
              return newCount;
            });
          }, 80);
      
          const departmentsInterval = setInterval(() => {
            setExperienceCount(prevCount => {
              const newCount = prevCount + 1;
              if (newCount === 15) clearInterval(departmentsInterval);
              return newCount;
            });
          }, 150);
      
          const researchInterval = setInterval(() => {
            setHappyClient(prevCount => {
              const newCount = prevCount + 1;
              if (newCount === 112) clearInterval(researchInterval);
              return newCount;
            });
          }, 50);
      
          const awardsInterval = setInterval(() => {
            setAwardsCount(prevCount => {
              const newCount = prevCount + 1;
              if (newCount === 5) clearInterval(awardsInterval);
              return newCount;
            });
          }, 200);
      
          // Limpieza de los intervalos al desmontar el componente
          return () => {
            clearInterval(doctorsInterval);
            clearInterval(departmentsInterval);
            clearInterval(researchInterval);
            clearInterval(awardsInterval);
          };

    },[])

    useEffect(()=>{
      
        const testimonialsSwiper = new Swiper('.testimonials-slider2', {
            speed: 600,
            loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false
            },
            slidesPerView: 'auto',
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            }
          });

        GetInitData();

        return () => {
            testimonialsSwiper.destroy();
        };
        
    },[])

    const handlePortfolioFilters = (event, categoria) =>{

        const clickedElement = event.currentTarget;

        // Remueve la clase 'filter-active' de todos los filtros y luego añade 'filter-active' al filtro clickeado
        const portfolioFilters = document.querySelectorAll('#portfolio-flters li');
        portfolioFilters.forEach(filter => {
            filter.classList.remove('filter-active');
        });

        // Añade la clase 'filter-active' al filtro clickeado
        clickedElement.classList.add('filter-active');


        if(categoria == "*" ){
            setDataFiltrada(data);
        }else{
            const filteredData = data.filter(item => item.Categoria == categoria);
            setDataFiltrada(filteredData);
        }
        
    }

    const GetInitData = async () =>{

        await GetBlogs();
        const Servicios = await ObtenerServicios();
        const Categorias = await ObtenerCategorias("COMPANIAS_EC");

        setServicios(Servicios);
        setCategorias(Categorias);
    }

    const GetBlogs = async() => {
        const Blogs = await ObtenerBlogs("COMPANIAS_EC");
        setData(Blogs);
        setDataFiltrada(Blogs);
    }

    const VerBlog = (blog,title) =>{
        let Id = blog
        history.push({
            pathname: '/blog/Ver/'+Id,
            state: { title: title }
        })
    }

    const VerBlogs = () =>{
        history.push({
            pathname: '/blogs'
        })
        window.scrollTo(0,0)
    }

    const RetornarFecha = (fecha) => {

        const createReview = new Date(fecha);
        let Fecha =
          (createReview.getDate() < 10 ? "0" : "") +
          createReview.getDate() +
          "-" +
          (createReview.getMonth() + 1 < 10 ? "0" : "") +
          (createReview.getMonth() + 1) +
          "-" +
          createReview.getFullYear();
        return Fecha;

    };

    const BLOGS = dataFiltrada.map((Estados,index) =>{

        let Imagen = Estados.FotoPortada;

        let FECHA = RetornarFecha(Estados.FechaPublicacion);


        return (
            <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${Estados.Categoria}`}>
                <div className="portfolio-wrap">
                    <img width={'100%'} src={Imagen} className="img-fluid" alt=""></img>
                    <div className="portfolio-info">
                        <h4>{Estados.Titulo}</h4>
                        <p>compañias_ec</p>
                        <p>{FECHA}</p>
                        <div className="portfolio-links">
                            <a onClick={()=>VerBlog(Estados.Id,Estados.Titulo)} title="Ver Blog"><i className='bx bx-fullscreen'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        )
        
    });

    const REDIRECCIONAR = (Link) =>{
        window.open(Link);
    }

    const SERVICIOS = servicios.map((Data,index) =>{

        return (
            
                <>
                    {
                        Data.Link !=null &&  Data.Link !='' ?
                            <div key={Data.Id} onClick={()=>REDIRECCIONAR(Data.Link)} className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 servicio-items" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className={Data.Icono} style={{color: 'white'}}></i></div>
                                    <h4><a target='_blank' href={Data.Link}>Servicios jurídicos integrales</a></h4>
                                    <p>{Data.Descripcion}</p>
                                </div>
                            </div>
                            :
                            <div key={Data.Id} onClick={()=>CONTROLADOR_MODAL(Data.Titulo,Data.Contenido)} className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 servicio-items" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box">
                                    <div className="icon"><i className={Data.Icono} style={{color: 'white'}}></i></div>
                                    <h4 onClick={()=>CONTROLADOR_MODAL(Data.Titulo,Data.Contenido)}><a>{Data.Titulo}</a></h4>
                                    <p>{Data.Descripcion}</p>
                                </div>
                            </div>     
                    }
                </>
            
            
        )
    });

    const CONTROLADOR_MODAL = (Titulo,Contenido) =>{
        setInfoServicios({
            ...infoServicios,
            Titulo : Titulo,
            Contenido : Contenido
        })
        AbrirModal();
    }

    const CATEGORIAS = categorias.map((Estado) =>{
        return (
            <li key={Estado.Id} onClick={(event)=>handlePortfolioFilters(event, Estado.Descripcion)} data-filter={"."+Estado.Descripcion}>{Estado.Descripcion}</li>
        )
    });

    const SOLICITAR = () =>{
        AbrirModal();
        setMensaje({
            ...mensaje,
            Tema : infoServicios.Titulo,
            Mensaje : "Hola me interesa aprender sobre " + infoServicios.Titulo
        })
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setMensaje((mensaje) => ({
            ...mensaje,
            [name]: value,
          }));
        
    };

    const ENVIAR_MENSAJE = () => {

        if(VALIDAR_FORMULARIO()){

            let JsonGuardar = {
                token:"015ec0d618fe3fa9c7d0a1abda2b159c",
                Nombre:mensaje.Nombre,
                Correo:mensaje.Correo,
                Tema:mensaje.Tema,
                Mensaje:mensaje.Mensaje,
            }

            fetch(host+'/API_COMPANIAS/procedures/mensaje/create.php',{
                method:'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },    
                body: JSON.stringify(JsonGuardar)                      
            }).then(response => response.json())
            .then((responseJson)=>{
    
                if(responseJson.status==="OK"){
                    message.success(responseJson.message);
                    setMensaje((mensaje) => ({
                            ...mensaje,
                            Nombre: "",
                            Correo: "",
                            Tema: "",
                            Mensaje: ""
                      }));
                }else{
                    message.error(responseJson.message);
                }
                
            })
            .catch(function(error) {
                console.error(error);             
            });
        }
    }

    const VALIDAR_FORMULARIO = () => {
        if(
            VALIDAR_NOMBRE() &&
            VALIDAR_CORREO() &&
            VALIDAR_TEMA() &&
            VALIDAR_MENSAJE()
        ){
            return true;
        }
        return false;
    }

    const VALIDAR_NOMBRE = () => {
        if(mensaje.Nombre=="" || mensaje.Nombre==null){
            message.error("Ingrese su nombre");
            return false;
        }
        return true;
    }

    const VALIDAR_CORREO = () => {
        const  rex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(rex.test(mensaje.Correo)){
            return true;
        }
        message.error('Ingrese un correo valido');
        return false
    }
    
    const VALIDAR_TEMA = () => {
        if(mensaje.Tema=="" || mensaje.Tema==null){
            message.error("Ingrese un tema");
            return false;
        }
        return true;
    }

    const VALIDAR_MENSAJE = () => {
        if(mensaje.Mensaje=="" || mensaje.Mensaje==null){
            message.error("Ingrese un mesnaje");
            return false;
        }
        return true;
    }

    return (
        <>

            <section id="hero" className="d-flex align-items-center justify-content-center">
                
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                        <h1>Start Up legal para emprendedores<span>.</span></h1>
                        <h2>Soluciones jurídicas personalizadas</h2>
                        </div>
                    </div>

                    <div className="row gy-4 mt-2 justify-content-center" >
                        <a key={1} className="nav-link scrollto col-xl-3 col-md-6 col-12" href="#cta" >
                            <div className="icon-box">
                                <i className="ri-briefcase-fill"></i>
                                <h3><span className="nav-link scrollto label-hero">Constitución de Compañías SAS</span></h3>
                                <p className='descripcion-servicios' >Crea tu compañía SAS con Compañías EC y recibirás servicios adicionales. 
                                    Chequea si esposible utilizar la SAS para tu negocio. Honorarios desde <span style={{fontWeight:'bold'}}>$ 400</span></p>
                            </div>
                        </a>
                        <a key={2} className="nav-link scrollto col-xl-3 col-md-6 col-12" href="#cta" >
                            <div className="icon-box">
                                <i className="ri-scales-3-fill"></i>
                                <h3><span className="nav-link scrollto label-hero">Registro de Marca</span></h3>
                                <p className='descripcion-servicios' >Protege tu activo intangible contra el uso desautorizado de 
                                terceros. Honorarios desde <span style={{fontWeight:'bold'}}>$ 400</span></p>
                            </div>
                        </a>
                        <a key={3} className="nav-link scrollto col-xl-3 col-md-6 col-12" href="#cta" >
                            <div className="icon-box">
                                <i className="ri-registered-line"></i>
                                <h3><span className="nav-link scrollto label-hero">Asesoría Jurídica Virtual</span></h3>
                                <p className='descripcion-servicios' >Si eres emprendedor reserva una cita virtual en la 
                                fecha y hora que desees.</p>

                            </div>
                        </a>
                        <a key={4} className="nav-link scrollto col-xl-3 col-md-6 col-12" href="#cta" >
                            <div className="icon-box">
                                <i className="ri-calendar-todo-line"></i>
                                <h3><span className="nav-link scrollto label-hero">Capacitaciones Jurídicas para emprendedores</span></h3>
                                <p className='descripcion-servicios' >Varias modalidades: conferencias, talleres, bootcamp, mentoring legal, etc</p>
                            </div>
                        </a>
                        
                    </div>

                </div>
            </section>

            <main id="main">

                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                            <a target='_blank' href='https://www.instagram.com/p/CSNseojs6Vx/'>
                                <img style={{cursor:'pointer'}} src="assets/img/portfolio/1.JPG" className="img-fluid" alt=""></img>
                            </a>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                            <h3>Emprende con seguridad gracias a nuestro gran equipo de profesionales</h3>
                            <br></br>
                            <p className="fst-italic">
                            Emprender a veces es ocasión de dudas y miedos por los posibles conflictos que se puedenoriginar como: multas con entidades públicas, 
                            indemnizaciones a terceros, carteraincobrable, etc. Por eso CompañíasEC cuenta con un grupo de expertos para la asesoríajurídica integral a emprendedores.
                            </p>
                            <ul>
                            <li><i className="ri-check-double-line"></i> Arriesga sólo aquello que puedas permitirte perder.</li>
                            <li><i className="ri-check-double-line"></i> Nunca pienses que eres la única persona que tiene toda la información relevante respecto de tu negocio.</li>
                            <li><i className="ri-check-double-line"></i> Sueña e ilusiónate con tu proyecto, pero siendo realista.</li>
                            <li><i className="ri-check-double-line"></i> Negocia aspectos importantes con tus seres queridos.</li>
                            </ul>
                            <p>
                            No basta con tener una buena idea, ilusión y conocimientos necesitamos también considerar aspectos legales que pueden facilitar 
                            por completo tu visión emprendedora. ¿Y sabes que? ... Estas en el lugar indicado.
                            </p>
                        </div>
                        </div>

                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container">

                        <div className="row">
                        <Carousel dotPosition={"right"} autoplay>
                                <img src='https://companiasec.com/API_COMPANIAS/img/Carrusel/mision-vision.jpg'></img>
                                <img src='https://companiasec.com/API_COMPANIAS/img/Carrusel/mision.jpg'></img>
                                <img src='https://companiasec.com/API_COMPANIAS/img/Carrusel/vision.jpg'></img>
                        </Carousel>
                        <div className="col-lg-12 col-xl-6 ">
                            <div className="icon-box mt-5 mt-lg-0">
                            <i className="bx bx-receipt"></i>
                            <h4>Misión</h4>
                            <p>Prestar servicios jurídicos enfocados en el área corporativa como forma de contribuir a la 
                                sociedad ecuatoriana y generar valor agregado diferenciándose en el mercado por el uso de 
                                las nuevas tecnologías en el ámbito del Derecho.</p>
                            </div>
                            <div className="icon-box mt-5">
                            <i className="bx bx-images"></i>
                            <h4>Visión</h4>
                            <p>Ser un estudio jurídico referente en el Ecuador con el mejor estandar de calidad y 
                                eficiencia en la prestación de servicios jurídicos integrales con la inclusión de profesionales 
                                de varias áreas del Derecho, siempre de la mano de las nuevas tecnologías.</p>
                            </div>
                            <div className="icon-box mt-5">
                            <i className="bx bx-cube-alt"></i>
                            <h4>Modalidad</h4>
                            <p>Contamos con las herramientas adecuadas para la asesoría jurídica de modo virtual y presencial.</p>
                            </div>
                            <div className="icon-box mt-5">
                            <i className="bx bx-shield"></i>
                            <h4>Seguridad</h4>
                            <p>Brindamos seguridad y confidencialidad en nuestros servicios.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                        <h2>Sección</h2>
                        <p>Servicios</p>
                        </div>

                        <div className="row">
                            {SERVICIOS}
                        </div>
                        

                    </div>
                </section>

                
                <section id="cta" className="cta">

                    <InlineWidget
                        url="https://calendly.com/companias_ec/evento" 
                    />
                    
                </section>

                <section id="cta-pasos" className="cta-pasos">
                    <div className="container">
                        <Steps current={2} percent={100} items={steps} />
                    </div>
                </section>
                
                <section id="portfolio" className="portfolio">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                        <h2>Sección</h2>
                        <p>Blogs</p>
                        </div>

                        <div className="row" data-aos="fade-up" data-aos-delay="100">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                            <li key={"indexTodos"} onClick={(event)=>handlePortfolioFilters(event, "*")} data-filter="*" className="filter-active">Todos</li>
                            {CATEGORIAS}
                            </ul>
                        </div>
                        </div>

                        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
                            {BLOGS}
                        </div>
                        <p onClick={()=>VerBlogs()} className="ver-todo" ><i style={{marginRight:'10px', fontSize:'30px'}} className="ri-arrow-right-s-fill"></i>ver todo</p>

                    </div>
                </section>

                <section id="counts" className="counts">
                    <div className="container" data-aos="fade-up">

                        <div className="row no-gutters">
                        <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay="100"></div>
                        <div className="col-xl-7 ps-0 ps-lg-5 pe-lg-1 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay="100">
                            <div style={{margin: '10px'}} className="content d-flex flex-column justify-content-center">
                            <h3>Reputación | Compañías_ec</h3>
                            <p>
                                Compañías_ec nace en el contexto de la pandemia del año 2020 con la finalidad de brindar soluciones 
                                jurídicas a emprendedores utilizando las nuevas tecnologías y ayudándose de las redes sociales como forma 
                                difusión de sus servicios.
                            </p>
                            <p>
                                La oferta de servicios jurídicos está enfocada en solucionar los diferentes retos jurídicos que enfrentan 
                                los emprendedores para lo cual contamos con un grupo de profesionales expertos en: derecho laboral, 
                                derecho societario, derecho civil y derecho mercantil.
                            </p>
                            <b>
                                Contamos también con capacitaciones jurídicas para emprendedores en diversos formatos: 
                                conferencias, mentoring legal y bootcamp
                            </b>
                            
                            <div className="row">
                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                <div className="count-box">
                                    {/* <i className="bi bi-emoji-smile"></i> */}
                                    <i className='bx bx-happy-alt'></i>
                                    <span> + {happyClient}</span>
                                    <p><strong>Clientes felices</strong> en todas las ciudades del ecuador</p>
                                </div>
                                </div>

                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                <div className="count-box">
                                    <i className='bx bx-briefcase-alt-2'></i>
                                    <span> {proyectCount}</span>
                                    <p><strong>Proyectos</strong> realizados con altos estandares de calidad</p>
                                </div>
                                </div>

                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                <div className="count-box">
                                    <i className='bx bx-time-five'></i>
                                    <span> {experienceCount}</span>
                                    <p><strong>Años de experiencia</strong> en el mercado ecuatoriano</p>
                                </div>
                                </div>

                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                <div className="count-box">
                                    <i className='bx bx-award'></i>
                                    <span> {awardsCount}</span>
                                    <p><strong>Reconocimientos</strong> a nivel profesional</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                    </div>
                </section>

                


                <section id="testimonials" className="testimonials">
                    
                    <div className="swiper-container testimonials-slider2">
                        <div className="swiper-wrapper">
                            {/* Aquí deberías tener tus slides */}
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=""></img>
                                    <h3>Abg. Dayana Puga</h3>
                                    <h4>Socia Directora</h4>
                                    <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Las actividades de emprendedores se encuentran reguladas por el derecho.
                                    Por eso es necesario tener la asesoría legal para evitar problemas.
                                    Compañias_ec cuenta con la experiencia que tu emprendimiento requiere.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=""></img>
                                    <h3>Abg. Dayana Puga</h3>
                                    <h4>Socia Directora</h4>
                                    <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Las actividades de emprendedores se encuentran reguladas por el derecho.
                                    Por eso es necesario tener la asesoría legal para evitar problemas.
                                    Compañias_ec cuenta con la experiencia que tu emprendimiento requiere.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>

                </section>

                

                <section id="contact" className="contact">
                    <div className="container">

                        <div className="section-title">
                        <h2>Sección</h2>
                        <p>Contáctanos</p>
                        </div>

                        <div>
                        {/* <iframe style={{border:'0', width: '100%', height: '270px'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255168.25640109816!2d-80.1201880604307!3d-2.1521517136893467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d13cbe855805f%3A0x8015a492f4fca473!2sGuayaquil!5e0!3m2!1ses!2sec!4v1654228725635!5m2!1ses!2sec" frameBorder="0" allowFullScreen></iframe> */}
                        </div>

                        <div className="row mt-5">

                        <div className="col-lg-4">
                            <div className="info">
                            <div className="address">
                                <i className='bx bxs-map' style={{color:'white'}}></i>
                                <h4>Dirección:</h4>
                                <p>Guayaquil, Guayas, Ecuador</p>
                            </div>

                            <div className="email">
                                <i className='bx bx-envelope' style={{color:'white'}} ></i>
                                <h4>Correo:</h4>
                                <p>info@companiasec.com</p>
                            </div>

                            <div className="phone">
                                <i className='bx bx-phone' style={{color:'white'}}></i>
                                <h4>Teléfono:</h4>
                                <p>+593 999 459 124</p>
                            </div>

                            </div>

                        </div>

                        <div id="Mensaje" className="col-lg-8 mt-5 mt-lg-0">

                            <div className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                    <input onChange={handleChange} value={mensaje.Nombre}  type="text" name="Nombre" className="form-control" id="Nombre" placeholder="Su Nombre" required></input>
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input onChange={handleChange} value={mensaje.Correo}  type="email" className="form-control" name="Correo" id="Correo" placeholder="Su Correo" required></input>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input onChange={handleChange} value={mensaje.Tema} type="text" className="form-control" name="Tema" id="Tema" placeholder="Tema" required></input>
                                </div>
                                <div className="form-group mt-3">
                                    <textarea onChange={handleChange} value={mensaje.Mensaje} className="form-control" name="Mensaje" rows="5" placeholder="Mensaje" required></textarea>
                                </div>
                                <div className="my-3">
                                    <div className="loading">Cargando</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Su mensaje ha sio enviado. Gracias!</div>
                                </div>
                                <div className="text-center"><button type="submit" onClick={()=>ENVIAR_MENSAJE()} style={{color:'white'}}>Enviar Mensaje</button></div>
                            </div>

                        </div>

                        </div>

                    </div>
                </section>

                <Modal
                    open={modal}
                    title={<div id='ContentModalServices'>Información</div>}
                    destroyOnClose={true}
                    centered
                    onCancel={AbrirModal}
                    width={700}
                    footer={[
                        <LinkS to="Mensaje" offset={-220} spy={true} exact='true' smooth={true} duration={1000}>
                            <Button type="primary" onClick={()=>SOLICITAR()}>
                                Solicitar
                            </Button>
                        </LinkS>
                        
                      ]}
                >
                    <div id='ContentModalServices' dangerouslySetInnerHTML={ { __html: infoServicios.Contenido } }></div>
                </Modal>
            </main>

            

        </>
    )
}

export default HomePage
