import React, { useState , useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ObtenerMensajes } from '../../../Api/ObtenerMensajes'
import {
  Table
} from "antd";


const pagination = {
  pageSize: 5,
};


const AdminMensajes = () => {

  const styles = useStyles();
  
  const [mensajes, setMensajes] = useState([]);

  useEffect(() => {
    GetMensajes();
  }, []);

  const GetMensajes = async () => {

    const Datos = await ObtenerMensajes();
    setMensajes(Datos);

  };


  const columns = [

    {
        title: "Nombre",
        dataIndex: "Nombre",
        key: "Nombre",
        //fixed: "left",
        // ...getColumnSearchProps('username','Nombre'),
        width: 100,
      },
    {
      title: "Fecha",
      dataIndex: "FechaRestada",
      key: "FechaRestada",
      width: 100,
    },
    {
        title: "Correo",
        dataIndex: "Correo",
        key: "Correo",
        width: 150,
    },
    {
        title: "Tema",
        dataIndex: "Tema",
        key: "Tema",
        width: 100,
    },
    {
        title: "Mensaje",
        dataIndex: "Mensaje",
        key: "Mensaje",
        width: 300,
    }
  ];


  return (
    <>
    <section id="hero-admin" className="d-flex align-items-center justify-content-center breadcrumbs">
      <div className="container">
          <ol>
              <li><a href="">Inicio</a></li>
              <li>Mensajes</li>
          </ol>
          <h2 style={{color:'white'}}>{"Mensajes Recibidos"}</h2>
      </div>
    </section>

    <div className={styles.Contenedor}>
        <Table
            className={styles.table}
            columns={columns}
            loading={false}
            key={mensajes.key}
            dataSource={mensajes}
            pagination={pagination}
            scroll={{ y: 350, x: 900 }}
        />
    </div>
    </>
  );
};

export default AdminMensajes;

const useStyles = makeStyles((theme) => ({
  Contenedor:{
    height:'100vh' , 
    paddingLeft:'110px' , 
    paddingRight:'110px',
    marginTop:'30px'
  },
  table: {
    width: '100%',
    margin: '0 auto'
  },
  BtnIngresar: {
    margin: "10px 0px 20px 0px",
    whiteSpace: "nowrap"
  },
  "@media (max-width: 750px)": {
    Contenedor:{
      height:'100vh' , 
      paddingLeft:'10px' , 
      paddingRight:'10px'
    }
  }
}));
