import React , {useEffect , useState } from 'react'
import { ObtenerBlogs } from '../../Api/ObtenerBlogs';
import { useHistory } from 'react-router-dom';
import { Empty } from 'antd';
import FilterBlog from '../BlogPage/FilterBlog';
import DataPaginada from '../BlogPage/DataPaginada';

const BlogPage = (props) => {

    const history = useHistory();
    const [dataBlogs ,setDataBlogs] = useState([]);

    const [itemOffset, setItemOffset] = useState(0);

    const RetornarFecha = (fecha) => {

        const createReview = new Date(fecha);
        let Fecha =
          (createReview.getDate() < 10 ? "0" : "") +
          createReview.getDate() +
          "-" +
          (createReview.getMonth() + 1 < 10 ? "0" : "") +
          (createReview.getMonth() + 1) +
          "-" +
          createReview.getFullYear();
        return Fecha;

    };

    
    useEffect(()=>{

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        if(props.location.state?.Blogs){
            setDataBlogs(props.location.state.Blogs);
        }else{
            GetBlogs();
        }
        
    },[])

    const GetBlogs = async () =>{
        const Blogs = await ObtenerBlogs("COMPANIAS_EC")
        setDataBlogs(Blogs);
    }
    
    

    const VerBlog = (blog,title) =>{
        let Id = blog
        history.push({
            pathname: '/blog/Ver/'+Id,
            state: { title: title }
        })
    }

    const BLOGS_ALL = dataBlogs.map((Estados,index) =>{


        let Imagen = Estados.Host + Estados.Portada;

        let FECHA = RetornarFecha(Estados.FechaRestada);
        let DESCRIPCION = Estados.Descripcion;
        let CREADOR = Estados.NombreAutor;

        return (
            <article key={index} className="entry">

                <div className="entry-img">
                    <img src={Imagen} alt="" className="img-fluid"></img>
                </div>

                <h2 className="entry-title">
                    <a onClick={()=>VerBlog(Estados.Id,Estados.Titulo)}>{Estados.Titulo}</a>
                </h2>

                <div className="entry-meta">
                    <ul>
                    
                    <li className="d-flex align-items-center"><img src={Estados.FotoAutor} style={{marginRight: '10px', width:'25px'}} className="rounded-circle float-left" width="30px" alt=""></img> <a href="blog-single.html">{CREADOR}</a></li>
                    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time dateTime={FECHA}>{FECHA}</time></a></li>
                    </ul>
                </div>

                <div className="entry-content">
                    <p style={{textAlign:'justify'}}>{DESCRIPCION} ...</p>
                    <div className="read-more">
                    <a onClick={()=>VerBlog(Estados.Id,Estados.Titulo)} >Leer mas</a>
                    </div>
                </div>

            </article>
        )
    });

    const FiltrarDatos = (Blogs,Opcion) =>{

        if(Blogs==="TODOS"){
            GetBlogs();
        }else{
            if(Opcion="RESETEAR"){
                setItemOffset("SI");
            }
            setDataBlogs(Blogs);
        }
        
    }

    return (
        <>

            <section id="hero-blog" className="d-flex align-items-center justify-content-center breadcrumbs">
                <div className="container">
                    <ol>
                        <li><a href="">Inicio</a></li>
                        <li>Blogs</li>
                    </ol>
                    <h2 style={{color:'white'}}>{"Blogs"}</h2>
                </div>
            </section>

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">

                    <div className="row">

                    <div className="col-lg-8 entries">

                    {
                        dataBlogs.length > 0 ?
                            // BLOGS_ALL
                            <DataPaginada data = { dataBlogs } Offset = {itemOffset} />
                            :
                            <Empty description={"No hay blogs que mostrar"}/>
                    }
    
                        
                    </div>

                    <FilterBlog
                        FiltrarDatos = {(e,opcion)=>FiltrarDatos(e,opcion)} 
                    />

                    </div>

                </div>
            </section>
        </>
    )
}

export default BlogPage
