import React , {useEffect , useState } from 'react'
import { ObtenerEquipo } from '../../Api/ObtenerEquipo';
const EquipoPage = () => {

    const [Equipo ,setDataEquipo] = useState([]);


    useEffect(()=>{
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
        GetEquipo();
    },[])

    const GetEquipo = async () =>{
        const Equipo = await ObtenerEquipo()
        setDataEquipo(Equipo);
    }

    const EQUIPO = Equipo.map((Data,Index) =>{

        return (
            
            <div key={Index} className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-up" data-aos-delay="100">
                <div className="member-img">
                    <img src={Data.Host + Data.Foto} className="img-fluid" alt=""></img>
                    <div className="social">
                    <a href={Data.Twitter} target='_black'><i className="bx bxl-twitter"></i></a>
                    <a href={Data.Facebook} target='_black'><i className="bx bxl-facebook"></i></a>
                    <a href={Data.Instagram} target='_black'><i className="bx bxl-instagram"></i></a>
                    <a href={Data.Linkedin} target='_black'><i className="bx bxl-linkedin"></i></a>
                    </div>
                </div>
                <div className="member-info">
                    <h4>{Data.Nombre}</h4>
                    <span>{Data.Profesion}</span>
                </div>
                </div>
            </div>
        )
        
    });

    return (
        <>
        <section id="hero-blog" className="d-flex align-items-center justify-content-center breadcrumbs">
            <div className="container">

                <ol>
                    <li><a href="">Inicio</a></li>
                    <li>Equipo</li>
                </ol>
                <h2 style={{color:'white'}}>Equipo</h2>

            </div>
        </section>
        <section id="team" className="team">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                <h2>Sección</h2>
                <p>Equipo</p>
                </div>

                <div className="row">

                    {EQUIPO}

                </div>

            </div>
        </section>
        </>
    )
}

export default EquipoPage
