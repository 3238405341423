import React, { useState , useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ObtenerAlertas } from '../../../Api/ObtenerAlertas'
import {
  Table
} from "antd";


const pagination = {
  pageSize: 5,
};


const AdminAlertas = () => {

  const styles = useStyles();
  
  const [alerta, setAlerta] = useState([]);

  useEffect(() => {
    GetAlertas();
  }, []);

  const GetAlertas = async () => {

    const Datos = await ObtenerAlertas();
    setAlerta(Datos);

  };


  const columns = [

    {
        title: "Correo",
        dataIndex: "Correo",
        key: "Correo",
        //fixed: "left",
        // ...getColumnSearchProps('username','Nombre'),
        width: 300,
    },
    {
        title: "Fecha",
        dataIndex: "FechaRestada",
        key: "FechaRestada",
        width: 100,
    }
  ];


  return (
    <>
    <section id="hero-admin" className="d-flex align-items-center justify-content-center breadcrumbs">
      <div className="container">
          <ol>
              <li><a href="">Inicio</a></li>
              <li>Alertas</li>
          </ol>
          <h2 style={{color:'white'}}>{"Alerta Correos"}</h2>
      </div>
    </section>

    <div className={styles.Contenedor}>
        <Table
            className={styles.table}
            columns={columns}
            loading={false}
            key={alerta.key}
            dataSource={alerta}
            pagination={pagination}
            scroll={{ y: 350, x: 900 }}
        />
    </div>
    </>
  );
};

export default AdminAlertas;

const useStyles = makeStyles((theme) => ({
  Contenedor:{
    height:'100vh' , 
    paddingLeft:'110px' , 
    paddingRight:'110px',
    marginTop:'30px'
  },
  table: {
    width: '100%',
    margin: '0 auto'
  },
  BtnIngresar: {
    margin: "10px 0px 20px 0px",
    whiteSpace: "nowrap"
  },
  "@media (max-width: 750px)": {
    Contenedor:{
      height:'100vh' , 
      paddingLeft:'10px' , 
      paddingRight:'10px'
    }
  }
}));
